import styled from 'styled-components';

export const TableContainer = styled.div`
  height: 100%;
  height: 350px;
  margin-top: 1rem;
`;
export const ButtonNavigation = styled.button`
  padding: 5px 10px;
  height: 40px !important;
`;

export const ButtonSeparator = styled.span`
  margin: 0px 1rem;
  width: 2px;
  border: 1px solid #bdbdbd;
  height: 50%;
`;
