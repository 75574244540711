import { DataGrid, GridCellParams } from '@material-ui/data-grid';
import React from 'react';
import { GoPencil } from 'react-icons/go';
import { MdDeleteForever } from 'react-icons/md';
import { ButtonRow } from '~/pages/Cliente/styles';
import { FormAssociado } from '~/pages/Produto/protocols';
import { TableContainer } from './styles';
import { Tooltip } from '@material-ui/core';

import { useProduto } from '~/pages/Produto/ProdutoContext';
import { Row } from 'react-bootstrap';
import { formatCurrencyAsText } from '~/utils/functions';

export const TableAssociado: React.FC = () => {
  const {
    formProdutoLoja,
    formAssociado,
    changeFormAssociado,
    setValue,
    getValues,
    associadosPai,
    associadosFilho,
    setAssociadosPai,
    setAssociadosFilho,
  } = useProduto();

  const associadoColumns = [
    {
      field: 'cod_produto',
      headerName: 'PLU',
      width: 300,
      disableColumnMenu: true,
    },
    {
      field: 'des_produto',
      headerName: 'Descrição',
      width: 180,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        const desProduto = row.des_produto;

        return (
          <Tooltip title={desProduto}>
            <span>{desProduto}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'des_departamento',
      headerName: 'Subgrupo',
      width: 180,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        const desDepartamento = row.des_departamento;

        return (
          <Tooltip title={desDepartamento}>
            <span>{desDepartamento}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'per_desc_assoc',
      headerName: '% Val. Venda',
      width: 130,
      sortable: false,
      // disableClickEventBubbling: true,
      disableColumnMenu: true,
    },
    {
      field: 'per_acresc_assoc',
      headerName: '% Val. Despesa',
      sortable: false,
      width: 130,
      // disableClickEventBubbling: true,
      disableColumnMenu: true,
    },
    {
      field: '',
      headerName: 'Ações',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      minWidth: 180,
      sortable: false,
      // disableClickEventBubbling: true,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        const onEdit = () => {
          const tipo_associado = getValues('tipo_associado');
          let reg;
          if (tipo_associado === 1) {
            reg = associadosPai.find((item) => {
              return item.cod_produto === row.cod_produto;
            });
          }
          if (tipo_associado === 2) {
            reg = associadosFilho.find((item: any) => {
              return item.cod_produto === row.cod_produto;
            });
          }
          setValue('per_desc_assoc', row.per_desc_assoc);
          setValue('per_acresc_assoc', row.per_acresc_assoc);
          setValue('assoc_id_edit', row.id);
          setValue('editAssociado', {
            value: reg.cod_produto,
            label: reg.des_produto,
          });

          if (reg) {
            changeFormAssociado({
              cod_associado: {
                ...formAssociado.cod_associado,
                value: reg.cod_produto,
                isInvalid: reg.cod_produto === undefined,
              },
              qtd_estoque: {
                ...formAssociado.qtd_estoque,
                value: reg.qtd_estoque,
              },
              des_produto: {
                ...formAssociado.cod_associado,
                value: reg.des_produto,
                isInvalid: false,
              },
              cod_produto: {
                ...formAssociado.cod_associado,
                value: reg.cod_produto,
                isInvalid: false,
              },
              des_departamento: {
                ...formAssociado.cod_associado,
                value: reg.des_departamento,
                isInvalid: false,
              },

              flg_atual_vda_assoc: {
                ...formAssociado.flg_atual_vda_assoc,
                value: reg.flg_atual_vda_assoc,
                isInvalid: false,
              },
              per_desc_assoc: {
                ...formAssociado.per_desc_assoc,
                value: reg.per_desc_assoc,
                isInvalid: false,
              },
              per_acresc_assoc: {
                ...formAssociado.per_acresc_assoc,
                value: reg.per_acresc_assoc,
                isInvalid: false,
              },
            });
          }
        };
        const onDelete = () => {
          const tipo_associado = getValues('tipo_associado');

          const associados =
            tipo_associado === 1
              ? getValues('associados_pai')
              : getValues('associados_filho');
          const filtered = associados.filter((item: FormAssociado) => {
            return Number(item.cod_produto) !== Number(row.cod_produto);
          });
          // handleClearAssoc();

          const filterMap = filtered.map((item: any) => {
            return {
              id: item.cod_produto,
              cod_produto: item.cod_produto,
              des_produto: item.des_produto,
              des_departamento: item.des_departamento,
              per_desc_assoc: formatCurrencyAsText(item.per_desc_assoc),
              qtd_estoque: item.qtd_estoque,
              per_acresc_assoc: formatCurrencyAsText(item.per_acresc_assoc),
              flg_atual_vda_assoc: item.flg_atual_vda_assoc,
            };
          });

          if (tipo_associado === 1) {
            setValue('associados_pai', filterMap);
            setAssociadosPai(filterMap);
          }
          if (tipo_associado === 2) {
            setValue('associados_filho', filterMap);
            setAssociadosFilho(filterMap);
          }
        };

        return (
          <>
            <ButtonRow type="button" onClick={onEdit}>
              <GoPencil size={20} style={{ color: '#72ab90' }} /> Editar
            </ButtonRow>
            <ButtonRow type="button" onClick={onDelete}>
              <MdDeleteForever size={20} style={{ color: '#e63c3c' }} /> Excluir
            </ButtonRow>
          </>
        );
      },
    },
  ];

  return (
    <Row>
      <TableContainer>
        <DataGrid
          rows={
            formProdutoLoja.tipo_associado.value === 1
              ? associadosPai
              : associadosFilho
          }
          columns={associadoColumns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          hideFooterSelectedRowCount
          disableColumnSelector
          localeText={{
            noRowsLabel: 'Nenhum registro encontrado',
            columnMenuLabel: 'Menu',
            columnMenuFilter: 'Filtrar',
            columnMenuHideColumn: 'Esconder',
            columnMenuUnsort: 'Não ordenar',
            columnMenuSortAsc: 'Ordernar ASC',
            columnMenuSortDesc: 'Ordernar DESC',
            columnMenuShowColumns: 'Mostrar columnas',
          }}
        />
      </TableContainer>
    </Row>
  );
};
