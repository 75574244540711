import React, { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BuscaProduto } from '~/pages/Produto/BuscaProduto';
import { useProduto } from '~/pages/Produto/ProdutoContext';
import ToggleDefault from '~/components/ToggleDefault';
import { ClearButton, ConfirmButton } from '~/components/Buttons';
import {
  FormAssociado,
  formAssociadoBlank,
  Associado,
} from '~/pages/Produto/protocols';
import { toast } from 'react-toastify';
import { InputPercent } from '~/components/NovosInputs';
import { moedaFormatada } from '~/components/Inputs/InputPercent2/formatMask';
import Swal from 'sweetalert2';
import { formatCurrencyAsText } from '~/utils/functions';
import { v4 } from 'uuid';

export const Form: React.FC = () => {
  const {
    formProduto,
    formProdutoLoja,
    changeFormProdutoLoja,
    changeFormAssociado,
    formAssociado,
    register,
    control,
    formState: { errors },
    setValue,
    getValues,
    setAssociadosPai,
    associadosPai,
    setAssociadosFilho,
    associadosFilho,
  } = useProduto();

  const [init, setInit] = useState(false);

  const validaInputsParaSubmit = useCallback(
    (inputs: FormAssociado): boolean => {
      for (let i = 0; i < Object.keys(inputs).length; i++) {
        if (
          formAssociado[Object.keys(inputs)[i] as keyof FormAssociado]
            .isRequired &&
          formAssociado[Object.keys(inputs)[i] as keyof FormAssociado].isInvalid
        ) {
          return true;
        }
      }
      return false;
    },
    [formAssociado],
  );

  function handleClearAssoc() {
    const { ref } = formAssociado.cod_associado;

    if (ref.current) {
      ref.current.handleClearProduto();
    }
    setInit(false);
    changeFormAssociado(formAssociadoBlank);
    setValue('per_desc_assoc', '');
    setValue('per_acresc_assoc', '');
    setValue('editAssociado', { value: undefined, label: '' });
  }

  const messageToClearEquivalentes = async () => {
    const { isConfirmed } = await Swal.fire({
      title: `Deseja realmente alterar o papel?`,
      text: 'Alternar o papel do Associado (Pai ou Filho) irá limpar as dependências já existente',
      showDenyButton: true,
      confirmButtonText: 'SIM',
      denyButtonText: 'Não',
    });

    return isConfirmed;
  };

  async function handleAddAssoc() {
    if (validaInputsParaSubmit(formAssociado)) {
      setInit(true);
      return;
    }

    const { tipo_associado, assoc_id_edit } = getValues();

    if (formProduto.cod_produto.value === formAssociado.cod_associado.value) {
      return toast.warning('Não é possivel associar um produto a ele mesmo');
    }

    setInit(false);
    const assoc =
      formProdutoLoja.tipo_associado.value === 1
        ? associadosPai
        : associadosFilho;

    const hasExists = assoc.find((item: any) => {
      return item.id === assoc_id_edit;
    });

    if (hasExists) {
      const newAssociados = assoc.map((item: any) => {
        if (item.id === assoc_id_edit) {
          item.cod_produto = formAssociado.cod_associado.value;
          item.des_produto = formAssociado.des_produto.value;
          item.des_departamento = formAssociado.des_departamento.value;
          item.per_desc_assoc = formatCurrencyAsText(
            formAssociado.per_desc_assoc.value || 0,
          );
          item.qtd_estoque = formAssociado.qtd_estoque.value;
          item.per_acresc_assoc = formatCurrencyAsText(
            formAssociado.per_acresc_assoc.value || 0,
          );
          item.flg_atual_vda_assoc = formAssociado.flg_atual_vda_assoc.value;
        }
        return item;
      });

      setValue('associados', newAssociados);

      if (tipo_associado === 1) {
        setAssociadosPai(newAssociados);
        setValue('associados_pai', newAssociados);
      }
      if (tipo_associado === 2) {
        setAssociadosFilho(newAssociados);
        setValue('associados_filho', newAssociados);
      }

      setValue('assoc_id_edit', '');

      handleClearAssoc();
      return changeFormProdutoLoja({
        ...formProdutoLoja,
        Associados: {
          ...formProdutoLoja.Associados,
          associados: newAssociados,
        },
      });
    }

    if (formProdutoLoja.tipo_associado.value === 1) {
      if (
        associadosPai.length > 0 &&
        associadosPai[0].cod_produto !== formAssociado.cod_produto
      ) {
        return toast.warning(`O Produto pode conter apenas um pai.`);
      }
    }

    const regs: Associado = {
      id: v4(),
      cod_produto: formAssociado.cod_associado.value,
      des_produto: formAssociado.des_produto.value,
      des_departamento: formAssociado.des_departamento.value,
      per_desc_assoc: formatCurrencyAsText(
        formAssociado.per_desc_assoc.value || 0,
      ),
      qtd_estoque: formAssociado.qtd_estoque.value,
      per_acresc_assoc: formatCurrencyAsText(
        formAssociado.per_acresc_assoc.value || 0,
      ),
      flg_atual_vda_assoc: formAssociado.flg_atual_vda_assoc.value,
    };

    if (tipo_associado === 1) {
      if (associadosFilho.length > 0) {
        const shouldClear = await messageToClearEquivalentes();
        if (shouldClear) {
          setAssociadosFilho([]);
          setValue('associados_filho', []);
        } else {
          return;
        }
      }

      setAssociadosPai([...associadosPai, regs]);

      setValue('associados_pai', [...associadosPai, regs]);
    }
    if (tipo_associado === 2) {
      if (associadosPai.length > 0) {
        const shouldClear = await messageToClearEquivalentes();
        if (shouldClear) {
          setAssociadosPai([]);
          setValue('associados_pai', []);
        } else {
          return;
        }
      }

      setAssociadosFilho([...associadosFilho, regs]);
      setValue('associados_filho', [...associadosFilho, regs]);
    }

    handleClearAssoc();
  }

  return (
    <>
      <Row>
        <Col sm={12} md={8} lg={6} xl={6}>
          <BuscaProduto
            valueEdit={getValues('editAssociado')}
            label={
              formProdutoLoja.tipo_associado.value === 1
                ? 'Produto Pai'
                : 'Produto Filho'
            }
            value={formAssociado.cod_associado.value}
            init={init}
            invalid={formAssociado.cod_associado.isInvalid}
            onChange={async (produto) => {
              const {
                value,
                codProduto,
                desProduto,
                desDepartamento,
                qtd_estoque,
              } = produto;
              if (
                formProdutoLoja.tipo_associado.value !== 1 &&
                produto.qtd_estoque > 0
              ) {
                await Swal.fire({
                  icon: 'warning',
                  title: `PLU contém estoque`,
                  text: 'Não é possível associá-lo ao PLU. Regularize a situação para prosseguir.',
                  showDenyButton: false,
                  confirmButtonText: 'OK',
                });

                const { ref } = formAssociado.cod_associado;

                if (ref.current) {
                  ref.current.handleClearProduto();
                }

                return;
              }

              setValue('editAssociado', { value: undefined, label: '' });
              changeFormAssociado({
                ...formAssociado,
                cod_associado: {
                  ...formAssociado.cod_associado,
                  value,
                  isInvalid: value === undefined,
                },
                qtd_estoque: {
                  ...formAssociado.qtd_estoque,
                  value: qtd_estoque,
                },
                des_produto: {
                  ...formAssociado.cod_associado,
                  value: desProduto,
                  isInvalid: false,
                },
                cod_produto: {
                  ...formAssociado.cod_associado,
                  value: codProduto,
                  isInvalid: false,
                },
                des_departamento: {
                  ...formAssociado.cod_associado,
                  value: desDepartamento,
                  isInvalid: false,
                },
              });
            }}
            refs={formAssociado.cod_associado.ref}
            placeholder="Informe pelo menos 3 caracteres do Código, Descrição ou GTIN"
          />
        </Col>

        <Col sm={12} md={4} lg={3} xl={2}>
          <ToggleDefault
            labelText="Assumir Valor de Venda do Produto Pai?"
            setChecked={formAssociado.flg_atual_vda_assoc.value}
            onSwitch={() =>
              changeFormAssociado({
                ...formAssociado,
                flg_atual_vda_assoc: {
                  ...formAssociado.flg_atual_vda_assoc,
                  value: !formAssociado.flg_atual_vda_assoc.value,
                },
              })
            }
          />
        </Col>

        <Col sm={12} md={4} lg={3} xl={2}>
          <InputPercent
            label="% Val. Venda"
            min={0}
            max={100}
            maxLength={5}
            decimals={2}
            placeholder="0,00"
            isDisabled={formProdutoLoja.tipo_associado.value !== 1}
            name="per_desc_assoc"
            register={register}
            control={control}
            isError={!!errors.per_desc_assoc}
            showIcon
            autoComplete="off"
            onInput={(ev: any) => {
              const serializedValue = moedaFormatada(ev.target.value);

              changeFormAssociado({
                ...formAssociado,
                per_desc_assoc: {
                  ...formAssociado.per_desc_assoc,
                  value: serializedValue.includes('-')
                    ? serializedValue.replace('-', '')
                    : serializedValue,
                },
              });
            }}
          />
        </Col>

        <Col sm={12} md={4} lg={3} xl={2}>
          <InputPercent
            label="% Val. Despesa"
            min={0}
            max={100}
            maxLength={5}
            decimals={2}
            placeholder="0,00"
            name="per_acresc_assoc"
            register={register}
            control={control}
            isError={!!errors.per_acresc_assoc}
            showIcon
            autoComplete="off"
            onInput={(ev: any) => {
              const serializedValue = moedaFormatada(ev.target.value);

              changeFormAssociado({
                ...formAssociado,
                per_acresc_assoc: {
                  ...formAssociado.per_acresc_assoc,
                  value: serializedValue.includes('-')
                    ? serializedValue.replace('-', '')
                    : serializedValue,
                },
              });
            }}
          />
        </Col>

        <Col
          sm={12}
          md={4}
          lg={9}
          xl={12}
          className="d-flex align-items-center align-self-end justify-content-end mt-2"
        >
          <ConfirmButton onClick={handleAddAssoc}>Adicionar</ConfirmButton>
          <ClearButton onClick={handleClearAssoc}>Limpar</ClearButton>
        </Col>
      </Row>
    </>
  );
};
