import styled from 'styled-components';

export const TableContainer = styled.div`
  height: 280px;
  width: 100%;
  margin-top: 1rem;
`;

export const AcoesContainer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;
// export const ButtonNavigation = styled.button`
//   padding: 5px 10px;
// `;

// export const ButtonSeparator = styled.span`
//   margin: 0px 1rem;
//   width: 2px;
//   border: 1px solid #bdbdbd;
//   height: 50%;
// `;

export const ButtonRow = styled.button`
  border: none;
  background-color: transparent;
  color: #3d3d3d;
  outline: none;

  &:nth-child(2) {
    margin-left: 10px;
  }
  &:hover {
    background-color: #f4f5f7 !important;
    color: #000;
    transition: 0.4s;
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }
`;
